<template>
    <div>
        <v-card
            outlined
        >
            <v-sheet
                v-if="!uuid"
                class="pa-3"
            >
                <v-skeleton-loader
                    class="mx-auto"
                    max-width="100%"
                    type="table-heading,list-item-avatar@3"
                />
            </v-sheet>
            <questions-list
                v-else
                hide-size
                shared-type="group"
                :shared-uuid="uuid"
                :payload="payload"
                :members="membersComputed"
                hide-author
                hide-date
                editable
                viewable
                show-summary
            />
        </v-card>
    </div>
</template>

<script>
import QuestionsList from '@apps/questions/components/QuestionsList'
import { getStudentsCollection } from '@apps/school/api/students'
import { memberFullName } from '@/components/Member/utils'

export default {
    name: 'GroupQuestionsShared',
    components: {
        QuestionsList
    },
    props: {
        uuid: {
            type: String,
            required: true
        }
    },
    computed: {
        payload() {
            return {
                author: this.$auth.user.uuid
            }
        },
        membersComputed() {
            return this.members.map(o => {
                return {
                    value: o.auth.uuid,
                    text: memberFullName(o, 'short')
                }
            })
        }
    },
    data() {
        return {
            members: []
        }
    },
    mounted() {
        this.fetchStudents()
    },
    methods: {
        async fetchStudents() {
            this.$lprogress.begin()

            try {
                const payload = {
                    group: this.uuid,
                    with: 'auth'
                }

                const response = await getStudentsCollection(payload)
                this.members = response.data.data
            } finally {
                this.$lprogress.end()
            }
        }
    }
}
</script>

<style lang=scss>

</style>
